import React from 'react'
import { Text, Flex, Image } from '@chakra-ui/react'
import starts1 from '../assets/1star.webp'
import starts2 from '../assets/2star.webp'
import starts3 from '../assets/3star.webp'
import starts4 from '../assets/4star.webp'
import starts5 from '../assets/5star.webp'
import downarrow from '../assets/Down arrow.webp'
import downarrowmobile from '../assets/downArrowmobile.webp'
import { motion } from 'framer-motion'

import { RotatingText } from 'react-simple-rotating-text'



const Home = () => {

    const Motionimage = motion(Image)
    const Motionflex = motion(Flex)
    return (
        <Flex
            flexDirection='column'
            h={{ base: '100%', sm: '100%', md:'100%' }}
            w='100%'
        // overflowY='scroll'
        >

            <Flex
                mt={{ base: '10%', sm: '5%', md: '0px' }}
                flexDirection='column'
                // overflowX='hidden'
                
                ml={{ base: '0%', sm: '0%', md: '8%' }}
                px={{ base: '8%', sm: '3%', md: '0%' }}
                h='max-content'
            >
                <Flex
                    justifyContent={{ base: 'center', sm: 'center', md: 'flex-start' }}
                    mt={{ base: '0%', sm: '0%', md: '3%' }}
                >
                    <Text
                        fontSize={{ base: '30px', sm: '30px', md: '7.2vw' }}
                        // fontSize='7.2vw'
                        color='var(--text-color)'
                        mb={{ base: '3%', sm: '3%', md: '0px' }}
                        fontWeight={{ base: '300', sm: '300', md: '100' }}
                    >
                        A marvel for your
                    </Text>
                </Flex>




                <Flex direction={{ base: 'column', md: 'row' }} wrap="nowrap">
                    <Flex
                        w={{ base: '100%', md: '40%' }}
                        justifyContent={{base:'center', sm:'center', md:'space-between'}}
                        overflow='hidden'
                        h='max-content'
                    >
                        <Motionimage
                            initial={{ x: -500, rotate: 360, opacity: 0, }}
                            animate={{ x: 0, rotate: 0, opacity: 1 }}
                            transition={{ duration: 1, delay: 0.8 }}
                            maxH={{ base: '100%', sm: '100%', md: '35%', lg: '35%', xl: '45%' }}
                            w={{ base: '18%', sm: '12%', md: '20%' }}
                            objectFit='cover'
                            src={starts1}


                        />
                        <Motionimage
                            initial={{ x: -500, rotate: 360, opacity: 0, }}
                            animate={{ x: 0, rotate: 0, opacity: 1 }}
                            transition={{ duration: 1, delay: 0.6 }}
                            maxH={{ base: '100%', sm: '100%', md: '35%', lg: '35%', xl: '45%' }}
                            w={{ base: '18%', sm: '12%', md: '20%' }}
                            objectFit='cover'
                            src={starts2}


                        />
                        <Motionimage
                            initial={{ x: -500, rotate: 360, opacity: 0, }}
                            animate={{ x: 0, rotate: 0, opacity: 1 }}
                            transition={{ duration: 1, delay: 0.4 }}
                            maxH={{ base: '100%', sm: '100%', md: '35%', lg: '35%', xl: '45%' }}
                            w={{ base: '18%', sm: '12%', md: '20%' }}
                            objectFit='cover'
                            src={starts3}


                        />
                        <Motionimage
                            initial={{ x: -500, rotate: 360, opacity: 0, }}
                            animate={{ x: 0, rotate: 0, opacity: 1 }}
                            transition={{ duration: 1, delay: 0.2 }}
                            maxH={{ base: '100%', sm: '100%', md: '35%', lg: '35%', xl: '45%' }}
                            w={{ base: '18%', sm: '12%', md: '20%' }}
                            objectFit='cover'
                            src={starts4}


                        />
                        <Motionimage
                            initial={{ x: -500, rotate: 360, opacity: 0, }}
                            animate={{ x: 0, rotate: 0, opacity: 1 }}
                            transition={{ duration: 1 }}
                            maxH={{ base: '100%', sm: '100%', md: '35%', lg: '35%', xl: '45%' }}
                            w={{ base: '18%', sm: '12%', md: '20%' }}
                            objectFit='cover'
                            src={starts5}


                        />
                    </Flex>

                    <Flex
                        w={{ base: '100%', md: '60%' }}
                        flexDirection={{ base: 'column', md: 'row' }}
                        justify={{ base: 'center', sm: 'center', md: "flex-start" }}
                        alignItems={{ base: 'center', sm: 'center', md: "flex-start" }}
                    // h='max-content'
                    >
                        <Motionimage
                            display={{ base: 'none', sm: 'none', md: 'block' }}
                            initial={{ y: -150, opacity: 0, scale: 0 }}
                            animate={{ y: 0, opacity: 1, scale: 1 }}
                            transition={{ duration: 1.75 }}
                            maxH='90%'
                            h={{ base: '150px', sm: '200px', md: '200px' }}
                            src={downarrow}

                        />
                        <Motionimage
                            display={{ base: 'block', sm: 'block', md: 'none' }}
                            initial={{ y: -150, opacity: 0, scale: 0 }}
                            animate={{ y: 0, opacity: 1, scale: 1 }}
                            transition={{ duration: 1.75 }}
                            maxH='90%'
                            h={{ base: '120px', sm: '100px', md: 'auto' }}
                            src={downarrowmobile}
                            transform={{base:'scale(1)', sm:'scale(0.7)', md:'scale(1)'}}
                            my={{ base: '30px', md: '0px' }}

                        />
                        <Flex
                            w={{ base: '100%', sm: '100%' }}
                            fontSize={{ base: '30px', sm: '35px', md: '5.5vw' }}
                            // justifyContent='center' // Centering text
                            // alignItems='center' // Centering text
                            fontWeight={{ base: '300', sm: '300', md: '100' }}
                            h='max-content'
                            overflow='hidden'
                            textAlign='center' // Ensure text is centered
                        >
                            <RotatingText
                                texts={['Marketing', 'Sales', 'Advertisement', 'Technology', 'Presence','Business Growth']}
                                duration={4}

                            />

                        </Flex>
                    </Flex>
                </Flex>


            </Flex>
            <Motionflex
                initial={{ y: 100, opacity: 0, scale: 0 }}
                animate={{ y: 0, opacity: 1, scale: 1 }}
                transition={{ duration: 1 }}
                overflow='hidden'
                my={{ base: '15%', sm: '0%', md: '0px' }}
                mb={{base:'15%', sm:'0%', md:'20px'}}
                // ml={{ base: '0%', sm: '0%', md: '8%' }}
                p={{ base: '3%', sm: '4%', md: '0%' }}
                w='100%'
                className="glitch-wrapper"
                justifyContent={{ base: 'center', sm: 'center', md: 'center' }}
                textAlign='center'
                letterSpacing='0.5px'
                h='max-content'
            >
                <Text
                    textAlign='center'
                    fontWeight={{ base: '300', sm: '300', md: '100' }}
                    // className="glitch"
                    // data-glitch="Experiance the Marworx Difference today"
                    as='span'
                    fontSize={{ base: '17px', sm: '20px' }}
                >
                    E
                    <Text
                     as='span'
                      color='#ff5f35'
                       className='highlightletter'
                        >
                        x
                    </Text>
                    perience the Marwor
                    <Text
                     as='span'
                      color='#ff5f35'
                       className='highlightletter'
                       >
                        x
                    </Text>
                    &nbsp;Difference today!
                </Text>
         
            </Motionflex>
        </Flex> 
    )
}

export default Home